// test.aip.aegion.com
const URL = 'https://test-api.aip.aegion.com';

const TAB_PREFIX = 'TEST';
const TAB_SUFFIX = '';

const aipurls = {
	admin: 'https://test-api.aip.aegion.com/admin',
	account: 'https://test-api.aip.aegion.com/account',
	assetview: 'https://test-api.aip.aegion.com/assetview',
	assetviewWS: 'wss://test-ws.aip.aegion.com/assetview',
	assetviewCorrelator: 'https://test-api.aip.aegion.com/assetview-correlator',
	customerusers: 'https://test-api.aip.aegion.com/customer-users',
	cisview: 'https://test-api.aip.aegion.com/cisview',
	cisviewextended: 'https://test-api.aip.aegion.com/cisview-extended',
	cisviewreadonly: 'https://test-api.aip.aegion.com/cisview-readonly',
	cisviewedits: `https://test-api.aip.aegion.com/cisview-edits`,
	dashboard: 'https://test-api.aip.aegion.com/dashboard',
	chargeline: 'https://test-api.aip.aegion.com/chargeline',
	chargelinebinary: 'https://test-api.aip.aegion.com/chargeline-binary',
	fieldline: 'https://test-api.aip.aegion.com/fieldline',
	scanline: 'https://test-api.aip.aegion.com/scanline',
	scanlineWorkflow: 'https://test-api.aip.aegion.com/scanline-workflow',
	scanlineReport: 'https://test-api.aip.aegion.com/scanlineReport',
	scanlineReportV2: 'https://test-api.aip.aegion.com/scanline-report-v2',
	scanlineReportV2WS: 'wss://test-ws.aip.aegion.com/scanline-report-v2',
	scanlineTags: 'https://test-api.aip.aegion.com/scanline-tags',
	templateViewer: 'https://test-api.aip.aegion.com/internal-template-viewer-2',
	templates: 'https://test-api.aip.aegion.com/templates',
	fieldlinebinary: 'https://test-api.aip.aegion.com/fieldline-binary',
	maps: 'https://test-api.aip.aegion.com/maps',
	liveline: 'https://test-api.aip.aegion.com/liveline',
	settings: 'https://test-api.aip.aegion.com/settings',
	uploaderbucket: 'aegion-aip-test',
	uploderbucketbasepath: 'https://test-api.aip.aegion.com/uploader',
	uploaderwebsocket: 'wss://test-ws.aip.aegion.com/uploader',
	uploaderbucketaccessurl: 'https://test-api.aip.aegion.com/uploader/access',
	uploaderfilesubmiturl:
		'https://test-api.aip.aegion.com/cisview-extended/postUploaderSubmit',
	parseFile: 'https://test-api.aip.aegion.com/parse-file',
	parseFileWS: 'wss://test-ws.aip.aegion.com/parse-file',
	mlServerApi: 'https://ml-test.aip.aegion.com/', // deprecated
	downloadReport: 'https://test-api.aip.aegion.com/dataprocessor-report',
	mlServerLambdaApi: 'https://test-api.aip.aegion.com/ml/',
	notifications: 'https://test-api.aip.aegion.com/aip-notifications',
	viewAs: 'https://test-api.aip.aegion.com/view-as',
	poolid: 'cognito-idp.us-west-2.amazonaws.com/us-west-2_SKDV5WDks',
	identifypoolid: 'us-west-2:64830314-b17d-483c-8a7f-0b3c84bf052c',
	clientid: '3hm5ot05frir6r0sb6bio87t3k',
	bucket: 'aegion-aip-test-nonversioned',
	versionedBucket: 'aegion-aip-test',
	dataIntegrity: 'https://test-api.aip.aegion.com/dataIntegrity'
};

export default aipurls;

export { URL, TAB_PREFIX, TAB_SUFFIX };
